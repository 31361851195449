<script>
import utilsMixin from '@shared/mixins/utils';

export default {
  mixins: [utilsMixin],
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    discount: {
      type: Object,
      required: true,
    },
  },
  computed: {
    discountValue() {
      return this.subscription.additional_data.next_payment_amount_without_discount - this.discountedPrice;
    },
    discountedPrice() {
      return this.subscription.additional_data.next_payment_amount_without_discount - this.subscription.additional_data.next_payment_amount_without_discount * (this.discount.percent_off / 100);
    },
  },
};
</script>

<template>
  <form class="modal-card" @submit.prevent="$emit('confirm', cancelData)" autocomplete="off">
    <div class="modal-card-head">
      <h2 class="modal-card-title">
        🚨 Dernière chance avant de perdre votre réduction ! 🚨
      </h2>
    </div>

    <div class="modal-card-body">
      <div class="content">
        <div class="mb-5">
          <p class="is-size-7 has-text-weight-light">Avant de partir, voici une offre spéciale que vous ne retrouverez pas ailleurs :</p>
          <div class="has-text-centered">
            <p class="is-size-6 mb-2 has-text-weight-medium">{{ discount.percent_off }}% de réduction pour une durée illimitée</p>
            <b-tag type="is-success">
              <b-icon icon="tag" />
              <span>Économisez {{ discountValue | formatPrice }}</span>
            </b-tag>
          </div>
        </div>
        <p class="is-size-7 has-text-weight-light">En acceptant cette offre maintenant, vous verrouillez un tarif réduit de <b>{{ discountedPrice | formatPrice }}</b> <span v-if="subscription.store_subscription.frequency === 'MONTHLY'">par mois</span><span v-else-if="subscription.store_subscription.frequency === 'YEARLY'">par an</span>. Et bien sûr, vous pouvez toujours annuler quand vous voulez.</p>
        <p class="is-size-7 has-text-weight-light">⏳ Cette offre ne vous sera proposée qu'une seule fois... Saisissez-la tant qu’il est encore temps !</p>
      </div>
    </div>

    <div class="modal-card-foot is-justify-content-flex-end btop-grey">
      <b-button type="is-text"  native-type="submit" class="has-text-danger">
        Je renonce à l’offre et je résilie
      </b-button>
      <b-button type="is-primary" @click="$emit('applyDiscount')">
        Oui, j'utilise la réduction
      </b-button>
    </div>
  </form>
</template>
