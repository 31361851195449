import { api } from '@shared/config/axios';

export default {
  subscribe(data, hasSubscription) {
    return api[hasSubscription ? '$put' : '$post']('/payments/subscriptions', data);
  },
  unsubscribe({
    password,
    cancel_reason,
    cancel_additional_text,
    subscriptionId,
    itemId,
  }) {
    const data = {
      password,
      cancel_reason,
      cancel_additional_text,
      _method: 'DELETE',
    };
    return api.$post(`/payments/subscriptions/${subscriptionId}/items/${itemId}`, data);
  },
  getPaymentMethods() {
    return api.$get('/payments/methods');
  },
  updatePaymentMethod(paymentMethodId, data) {
    return api.$put(`/payments/methods/${paymentMethodId}`, data);
  },
  connect(data) {
    return api.$post('/payments/connections', data);
  },
  getPayPalConnectData() {
    return api.$post('/payments/paypal/connections');
  },
  paypalConnect(data) {
    return api.$put('/payments/paypal/connections', data);
  },
  checkDiscount({ code, type }) {
    return api.$get('/payments/subscriptions/discounts', { params: { code, type } });
  },
  getSubscriptions() {
    return api.$get('/payments/subscriptions');
  },
  getSubscriptionProration(params) {
    return api.$get('/payments/subscriptions/previews', { params });
  },
  getPaymentLink(params) {
    return api.$get('/payments/url', { params });
  },
  getChurnDiscount() {
    return api.$get('/churn-discounts');
  },
};
