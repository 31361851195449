<script>
import debounce from 'lodash/debounce';
import Stripe from '@shared/services/Stripe';
import utilsMixin from '@shared/mixins/utils';
import { setTimeoutPromise } from '@shared/services/Utils';
import APIPayment from '@app/services/API/Payment';
import trainingsMixin from '@app/mixins/trainings';
import CancelSubscriptionForm from '@app/components/CancelSubscriptionForm.vue';
import ChurnConfirmation from '@app/components/ChurnConfirmation.vue';
import { api } from '@shared/config/axios';

const WEBHOOK_TIMEOUT = 3000;

export default {
  i18n: {
    messages: {
      fr: {
        messages: {
          forbidden_trainings: 'Vous avez des formations en statut "<strong>caché</strong>" ou "<strong>non répertorié</strong>". Ces statuts n\'étant pas disponibles pour le forfait DÉCOUVERTE, veuillez d\'abord supprimer ou publier les formations concernées.',
          forbidden_training_settings: '<strong>Vos paramètres actuels concernant les logs et temps de connexion</strong> (vérificateur d\'activité et comportement à adopter au changement d\'onglet) ne sont pas disponibles pour le forfait DÉCOUVERTE. Veuillez désactiver ces paramétrages dans <strong>Formations > Paramètres</strong>.',
          too_many_trainings_for_plan: 'Vous avez <strong>plus de 3 formations publiées</strong>, ce qui correspond au maximum de formations disponibles sur le forfait DÉCOUVERTE. Veuillez d\'abord supprimer ou mettre en brouillon les formations supplémentaires.',
        },
      },
    },
  },
  mixins: [trainingsMixin, utilsMixin],
  head: {
    title: 'Accompagnement',
  },
  data() {
    return {
      isLoading: false,
      isFetching: false,
      isChangePaymentMethodOpen: false,
      stripeError: null,
      stripeIsCompleted: false,
      frequency: 'YEARLY',
      plan: 'PRO',
      discountIsChecking: false,
      discount: '',
      discountData: null,
      subscription: null,
      paymentMethods: null,
      churnDiscounts: {},
    };
  },
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
    hasProPlan() {
      return this.$store.getters['auth/hasProPlan'];
    },
    hasPerkPlan() {
      return this.$store.getters['auth/hasPerkPlan'];
    },
    hasPercentPlan() {
      return this.$store.getters['auth/hasPercentPlan'];
    },
    isNotPercentPlan() {
      return this.$store.getters['auth/isNotPercentPlan'];
    },
    subscriptionPlan() {
      if (!this.subscription.store_subscription) {
        return null;
      }

      const plan = this.subscription.store_subscription.items.find((v) => v.type !== 'USER_ACCOUNT');

      return plan;
    },
    subscriptionFrequency() {
      if (!this.subscription.store_subscription) {
        return null;
      }

      return this.subscription.store_subscription.frequency;
    },
    subscriptionStartDate() {
      if (!this.subscription.store_subscription) {
        return null;
      }

      const subscription = this.subscription.store_subscription.items.slice();
      subscription.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      return subscription[0].created_at;
    },
    paymentMethodExp() {
      let month = this.paymentMethods[0].card_exp_month;
      const year = this.paymentMethods[0].card_exp_year;

      if (month == null || year == null) {
        return '';
      }

      month = month > 9 ? month : `0${month}`;

      return `${month} / ${year.toString().substring(2)}`;
    },
    price() {
      return this.$constants.PRICES[this.frequency][this.plan];
    },
    hasValidNewDiscount() {
      return this.discountData && ('code' in this.discountData);
    },
    hasDiscountOnSubscription() {
      if (!this.subscriptionStartDate) {
        return false;
      }

      const data = this.subscription.additional_data;

      return (data.percent_off > 0 || data.amount_off > 0);
    },
    cantSubscribe() {
      return (
        (this.subscriptionPlan && this.subscriptionPlan.type === this.plan)
        || (!this.stripeIsCompleted && !this.paymentMethods.length)
        || this.discountIsChecking
      );
    },
  },
  methods: {
    loadData() {
      this.isFetching = true;
      return Promise.all([
        this.loadSubscriptions(),
        this.loadPaymentMethods(),
        this.loadChurnDiscount(),
      ])
        .finally(() => {
          this.isFetching = false;

          if (this.subscriptionFrequency) {
            this.frequency = this.subscriptionFrequency;
          }
        });
    },
    loadSubscriptions() {
      return APIPayment.getSubscriptions()
        .then(({ data }) => (this.subscription = data));
    },
    loadPaymentMethods() {
      return APIPayment.getPaymentMethods()
        .then(({ data }) => (this.paymentMethods = data));
    },
    loadChurnDiscount() {
      return APIPayment.getChurnDiscount()
        .then(({ data }) => (this.churnDiscounts = data));
    },
    onPaymentMethodChange(paymentMethods) {
      this.paymentMethods = paymentMethods;
      this.isChangePaymentMethodOpen = false;
    },
    handle() {
      const needsStripeCompleted = !this.paymentMethods.length && !this.stripeIsCompleted;

      if (needsStripeCompleted) {
        return;
      }

      if (this.subscriptionStartDate) {
        const loader = this.$buefy.loading.open();
        APIPayment.getSubscriptionProration({
          type: this.plan,
          frequency: this.subscriptionFrequency,
          promo_code: this.discount || undefined,
        })
          .then(({ data }) => {
            let price;
            const subscriptionData = this.subscription.additional_data;

            if ((this.hasValidNewDiscount || this.hasDiscountOnSubscription) && this.plan !== 'EXPERT') {
              const discountData = this.hasValidNewDiscount
                ? this.discountData
                : subscriptionData;

              price = discountData.percent_off > 0
                ? (this.price * (1 - discountData.percent_off / 100))
                : (this.price - discountData.amount_off);

              price = utilsMixin.filters.formatPrice(price);
            } else {
              price = `${this.price} €`;
            }

            let prorataMessage;
            const currentPlanName = this.$t(`packs.${this.authStore.plan}`);

            if (data.next_payment_date) {
              prorataMessage = `
                <p>
                  Pour passer au nouveau Forfait, vous serez facturé immédiatement de
                  ${utilsMixin.filters.formatPrice(data.prorated_amount)} TTC
                  (prorata de votre période de facturation en cours),
                  puis de ${price} TTC à chaque date anniversaire de votre abonnement
                  (à partir du ${this.$moment.utc(data.next_payment_date).local().format('DD/MM/YY')})
                </p>
              `.trim();
            } else {
              prorataMessage = `
                <p>
                  En changeant de Forfait, vous perdrez immédiatement
                  <strong>tous vos avantages spécifiques au ${currentPlanName}</strong>.
                </p>
                <p>
                  Votre abonnement passera à ${price} à partir du
                  ${this.$moment.utc(subscriptionData.next_payment_date).local().format('DD/MM/YY')},
                  date de votre prochaine facture.
                </p>
              `.trim();
            }

            this.$buefy.dialog.confirm({
              message: `
                <div class="content">
                  ${prorataMessage}
                </div>
              `,
              confirmText: 'Souscrire',
              focusOn: 'cancel',
              onConfirm: () => this.subscribe(),
            });
          })
          .finally(() => loader.close());

        return;
      }

      this.subscribe();
    },
    subscribe() {
      let promise;
      const loader = this.$buefy.loading.open();
      this.isLoading = true;

      if (this.paymentMethods.length) {
        promise = APIPayment.subscribe({
          type: this.plan,
          frequency: this.frequency,
          promo_code: this.discount || undefined,
        });
      } else {
        promise = this.$refs.card.createPaymentMethod()
          .then((res) => APIPayment.subscribe({
            type: this.plan,
            frequency: this.frequency,
            payment_method: res.paymentMethod.id,
            promo_code: this.discount || undefined,
          }));
      }

      return promise.then(this.handleSubscription)
        .then(() => setTimeoutPromise(
          () => Promise.all([this.loadData(), this.$store.dispatch('auth/fetch')]),
          WEBHOOK_TIMEOUT,
        ))
        .then(() => {
          this.$showMessage.success();
          this.$scrollTo(this.$el);
        })
        .finally(() => {
          this.isLoading = false;
          loader.close();
        });
    },
    handleSubscription({
      data: {
        user, additional_data, store_subscription, subscription,
      },
    }) {
      this.$store.commit('auth/update', user);

      const { latest_invoice } = subscription;
      const { payment_intent } = latest_invoice;

      if (payment_intent) {
        const { client_secret, status } = payment_intent;

        if (status === 'requires_action' || status === 'requires_confirmation') {
          let confirmCardPayment;

          if (this.paymentMethods.length) {
            confirmCardPayment = Stripe.init(this.$env.stripePK)
              .then(() => Stripe.confirmCardPayment(client_secret, {
                payment_method: payment_intent.payment_method,
              }));
          } else {
            confirmCardPayment = this.$refs.card.confirmCardPayment(client_secret);
          }

          return confirmCardPayment.then(() => {
            this.$store.commit('auth/update', user);
            this.subscription = { additional_data, store_subscription };
          });
        }
      }

      this.$store.commit('auth/update', user);
      this.subscription = { additional_data, store_subscription };
      return null;
    },
    unsubscribeStorePlan() {
      if (!this.subscriptionPlan) {
        return;
      }

      const trainings = this.$store.state.trainings.list;

      if (!trainings) {
        const loader = this.$buefy.loading.open();
        this.$store.dispatch('trainings/fetch')
          .then((trainings) => this.confirmUnsubscribeStorePlan(trainings.data))
          .finally(() => loader.close());

        return;
      }

      this.confirmUnsubscribeStorePlan(trainings.data);
    },
    confirmUnsubscribeStorePlan(trainings) {
      const hasForbiddenTrainings = trainings.some((v) => ['UNLISTED', 'HIDDEN'].includes(v.status));

      if (hasForbiddenTrainings) {
        this.$buefy.dialog.alert(this.$t('messages.forbidden_trainings'));
        return;
      }

      const nbPublishedTrainings = trainings.filter((v) => v.status !== 'DRAFT').length;

      if (nbPublishedTrainings > 3) {
        this.$buefy.dialog.alert(this.$t('messages.too_many_trainings_for_plan'));
        return;
      }

      const trainingOptions = this.authStore.feature_options;
      if (trainingOptions && trainingOptions.customer_session
        && (trainingOptions.customer_session.inactivity_timeout != null
          || trainingOptions.customer_session.disabled_on_tab_quit)) {
        this.$buefy.dialog.alert(this.$t('messages.forbidden_training_settings'));
        return;
      }

      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: `
          La résiliation de votre abonnement sera immédiate et vous perdrez toutes les fonctionnalités
          du ${this.$t(`packs.${this.subscriptionPlan.type}`)} dès maintenant.
        `,
        focusOn: 'cancel',
        onConfirm: () => {
          if (!this.subscription.additional_data.has_churn_discount) {
            this.openChurnConfirmation();
          } else {
            this.openCancelSubscriptionForm();
          }
        },
      });
    },
    openChurnConfirmation() {
      let modal = this.$buefy.modal.open({
        parent: this,
        component: ChurnConfirmation,
        hasModalCard: true,
        props: {
          subscription: this.subscription,
          discount: this.subscription.store_subscription.frequency === 'MONTHLY' ? this.churnDiscounts.monthly : this.churnDiscounts.yearly,
          // subscriptionPlan: this.subscriptionPlan,
        },
        events: {
          applyDiscount: () => {
            const loader = this.$buefy.loading.open();
            api.$post('/churn-discounts')
              .then(({ data: { user, additional_data, store_subscription } }) => {
                this.$buefy.toast.open({
                  message: 'La réduction a été appliquée à votre abonnement',
                  type: 'is-success',
                });
                this.$emit('close');
                this.$store.commit('auth/update', user);
                this.subscription = { additional_data, store_subscription };

                Promise.all([
                  this.loadData(),
                  this.$store.dispatch('auth/fetch'),
                ]);
              })
              .catch((error) => {
                this.$buefy.toast.open({
                  message: 'Une erreur est survenue lors de l\'application de la réduction',
                  type: 'is-danger',
                });
                console.error('Error applying churn discount:', error);
              })
              .finally(() => {
                loader.close();
                modal.close();
              });
          },
          confirm: () => {
            modal.close();
            modal = this.openCancelSubscriptionForm();
          },
        },
      });
      return modal;
    },
    openCancelSubscriptionForm() {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: CancelSubscriptionForm,
        hasModalCard: true,
        props: {
          subscriptionPlan: this.subscriptionPlan,
        },
        events: {
          confirm: ({ password, cancel_reason, cancel_additional_text } = {}) => {
            const loader = this.$buefy.loading.open();
            APIPayment.unsubscribe({
              password,
              cancel_reason,
              cancel_additional_text,
              subscriptionId: this.subscription.store_subscription.id,
              itemId: this.subscriptionPlan.id,
            })
              .then(({ data: { user, additional_data, store_subscription } }) => {
                this.$store.commit('auth/update', user);
                this.subscription = { additional_data, store_subscription };
                return setTimeoutPromise(() => Promise.all([
                  this.loadData(),
                  this.$store.dispatch('auth/fetch'),
                  modal.close(),
                ]), WEBHOOK_TIMEOUT);
              })
              .catch((error) => {
                this.$buefy.toast.open({
                  message: error.response.data.message,
                  type: 'is-danger',
                });
              })
              .finally(() => {
                loader.close();
              });
          },
        },
      });

      return modal;
    },
    checkDiscount: debounce(function() {
      if (!this.discount) {
        this.discountData = null;
        return;
      }

      this.discountIsChecking = true;
      APIPayment.checkDiscount({ code: this.discount, type: this.plan })
        .then(({ data }) => (this.discountData = data))
        .finally(() => (this.discountIsChecking = false));
    }, 250),
    switchPlan(plan) {
      this.plan = plan;

      if (this.plan === 'EXPERT') {
        this.discount = '';
      }

      this.checkDiscount();
    },
    switchFrequency(frequency) {
      this.frequency = frequency;

      this.checkDiscount();
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<template>
  <div class="pcomp">
    <section>
      <h1 class="title">
        Nos accompagnements
      </h1>
      <div class="box content is-medium w-640 mx-auto is-size-7-mobile">
        <p>
          <strong>
            Besoin d’un avis expert avant de lancer votre formation ?
            Sécurisez le lancement de votre formation avec notre
            Audit de lancement personnalisé.
          </strong>
        </p>
        <p>
          🔍 Ce que vous obtenez :
          <ul>
            <li>Un <strong>rapport d’audit</strong> détaillé avec des recommandations concrètes.</li>
            <li>Une <strong>vidéo explicative</strong> où nos experts commentent vos contenus.</li>
            <li>Un <strong>échange individuel</strong> pour répondre à vos questions.</li>
            <li>
              <strong>+2 bonus</strong> exclusifs :<br/>
              ✅ Mémo de lancement : 10 étapes à ne pas oublier !<br/>
              ✅ Garantir la qualité de ma formation grâce aux bêta-tests
            </li>
          </ul>
        </p>
        <p>
          <strong>Pourquoi choisir notre audit ?</strong>
          <ul>
            <li>Identifier et corriger les points faibles avant le lancement.</li>
            <li>Gagner du temps en évitant les multiples allers-retours coûteux.</li>
            <li>Proposer une formation plus engageante grâce à notre méthodologie d’analyse sur 10 axes stratégiques.</li>
          </ul>
        </p>
        <p class="has-text-right">
          <b-button
            tag="a"
            size="is-larger"
            type="is-primary"
            class="mt-6"
            href="https://calendly.com/teachizy-et-vous/audit-de-lancement"
            target="_blank"
            rel="noreferrer noopener"
            outlined=""
          >
            ➡️ Je réserve mon audit
          </b-button>
        </p>
      </div>
    </section>

  </div>
</template>

<style lang="scss" scoped>
.pcomp {}
</style>
